import React, { useEffect, useState } from 'react'

import { Drawer, Input, Form, Button, Checkbox, Select } from 'antd'

import REQUESTS from '../../api/requests';

import ErrorMessage from '../../components/ErrorMessage';

const options = [
    {
        label: 'Monthly',
        value: 'monthly',
    },
    {
        label: 'Yearly',
        value: 'yearly',
    },
    {
        label: 'Lifetime',
        value: 'lifetime',
    },
]

export default function DNSDrawer({ open, onClose, getData, current }) {
    const [form] = Form.useForm();

    const [checkPlaylist, setCheckPlaylist] = useState(false)

    const [message, setMessage] = useState({
        type: false,
        text: "",
    });

    const onFinish = (values) => {

        setMessage({
            type: false,
            text: "",
        })

        const { dns, activation_type, check_playlist, max_activation_count } = values;

        console.log(values, 'values xxxxxxx')

        const body = {
            dns,
            activation_type,
            check_playlist,
        }

        if (max_activation_count) {
            body.max_activation_count = +max_activation_count
        }
        else {
            delete body.max_activation_count
        }

        function callback(data) {
            onClose();

            getData();
        }

        function errorCallback(error) {
            setMessage({
                type: false,
                text: error,
            })
        }

        if (current) {
            body.id = current.id;
            REQUESTS.FREE_DNS.PUT(body, callback, errorCallback)
        } else {
            REQUESTS.FREE_DNS.POST(body, callback, errorCallback)
        }

    };

    useEffect(() => {
        if (current) {
            form.setFieldsValue(
                {
                    dns: current.dns,
                    activation_type: current.activation_type,
                    check_playlist: current.check_playlist,
                    max_activation_count: current.max_activation_count
                },
            )

            if (current.check_playlist) {
                setCheckPlaylist(true)
            }

        } else {
            form.resetFields();
        }
    }, [current, open])

    useEffect(() => {
        if (!checkPlaylist) {
            form.setFieldsValue(
                {
                    activation_type: "lifetime",
                },
            )
        }
    }, [checkPlaylist])

    console.log(checkPlaylist, 'checkPlaylist vvvvvvv')

    return (
        <Drawer
            open={open}
            onClose={() => {
                onClose()
                form.resetFields();
            }}
            title={current ? "Edit DNS" : "Add DNS"}
        >
            <Form
                layout='vertical'
                form={form}
                onFinish={onFinish}
                initialValues={{
                    activation_type: 'lifetime',
                    max_activation_count: 0,
                    check_playlist: false
                }}
            >
                <Form.Item
                    label='DNS'
                    name="dns"
                    rules={[
                        {
                            required: true,
                            message: 'Please input DNS'
                        },
                        {
                            validator: (rule, value, callback) => {
                                const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
                                if (!value || urlPattern.test(value)) {
                                    callback();
                                } else {
                                    callback('Please enter a valid URL');
                                }
                            }
                        },
                    ]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    name="max_activation_count"
                    label="Max activation count"
                    rules={[
                        {
                            validator: (rule, value, callback) => {
                                const inputValue = parseInt(value, 10);

                                if (isNaN(inputValue) || inputValue < 0) {
                                    callback('Please enter a positive number or 0');
                                } else {
                                    callback();
                                }
                            }
                        },
                    ]}
                >
                    <Input type="number" placeholder="Max activation count" />
                </Form.Item>

                <Form.Item name="check_playlist" valuePropName="checked">
                    <Checkbox
                        checked={checkPlaylist}
                        onChange={(e) => setCheckPlaylist(e.target.checked)}>Check playlist</Checkbox>
                </Form.Item>

                <Form.Item label="Activation type" name="activation_type" >
                    <Select >
                        {
                            checkPlaylist && <Select.Option value="by_playlist">By playlist</Select.Option>
                        }
                        {
                            options.map(({ label, value }, index) => <Select.Option value={value}>{label}</Select.Option>)
                        }
                    </Select>
                </Form.Item>

                <ErrorMessage message={message} />

                <Form.Item style={{ textAlign: "center" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Drawer >
    )
}
