import React, { useState, useEffect } from 'react';

import { Table, Tag ,Empty} from 'antd';

import REQUESTS from '../../api/requests';

import getMyDate from '../../components/getMyDate';

import macMaskInput from '../../components/macMaskInput';

import getColumnSearchProps from '../../components/getColumnSearchProps';

export default function ActivationLogs() {
    const [limit, setLimit] = useState(10);

    const [total, setTotal] = useState(10);

    const [currentPage, setCurrentPage] = useState(1);

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState({});

    const [sort, setSort] = useState(["id", "DESC"]);

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            key: "id",
            align: "center",
            render: (text, record, index) => {
                if (currentPage === 1) {
                    return index + 1;
                } else {
                    return limit * (currentPage - 1) + index + 1;
                }
            },
        },
        {
            title: "Mac",
            align: "center",
            dataIndex: "mac",
            ...macMaskInput(),
        },
        {
            title: "By",
            align: "center",
            dataIndex: "by",
            ...getColumnSearchProps("by"),
        },
        {
            title: "Type",
            align: "center",
            dataIndex: "type",
            filters: [
                {
                    text: "Activated",
                    value: "activated",
                },
                {
                    text: "Deactivated",
                    value: "deactivated",
                },
            ],
            filterMultiple: false,
            render: (text) => <Tag color={text === "activated" ? "green" : 'volcano'} key={text}>
                {text}
            </Tag>
        },
        {
            title: "Date",
            align: "center",
            dataIndex: "createdAt",
            sorter: true,
            render: (text) => getMyDate(text),
        },

    ];

    const getActivationLogs = () => {
        setLoading(true);

        const query = {
            page: currentPage,
            limit,
            pagination: 1,
            sort: JSON.stringify(sort),
            search: {},
        }

        if (search?.type) {
            query.search["type"] = search.type[0];
        }

        if (search?.by) {
            query.search["by"] = search.by[0];
        }

        if (search?.mac) {
            query.search["mac"] = search.mac[0];
        }

        query.search = JSON.stringify(query.search);

        REQUESTS.DEVICE_ACTIVATIONS(query, (data) => {
            setLoading(false)
            setData(data.rows)
            setTotal(data.count)
        }, (err) => setLoading(false))
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
        setSearch(filters);
        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }
    };

    useEffect(() => {
        let timeout = setTimeout(() => {
            getActivationLogs()
        }, 500);

        return () => clearTimeout(timeout);

    }, [limit, currentPage, search, sort])

    return (
        <div>
            <p className="table-title">Activation Logs</p>
            <Table
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                size="small"
                scroll={{ x: "max-content" }}
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
            />
        </div>
    )
}
