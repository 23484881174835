import { useState, useEffect, useRef } from "react";

import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";
import classes from "../style/supportDrawer.module.scss";
import ErrorMessage from "../../../components/ErrorMessage";
import getMyDate from "../../../components/getMyDate";
import { Button, Form, Input, Drawer, Select, Switch } from "antd";
const { Option } = Select;
const { TextArea } = Input;

const SupportDrawer = ({
  onClose,
  visible,
  current,
  getData,
  translation,
  support,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const useScrollRef = useRef();
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });

  const onValuesChange = (e) => {
    setMessage({
      type: false,
      text: "",
    });
  };


  useEffect(() => {
    if (visible === false) {
      setLoading(false);
      onValuesChange();
    }
  }, [visible]);

  useEffect(() => {
    if (visible === true) {
      scrollIntoView();
    }
  }, [visible]);

  function scrollIntoView() {
    setTimeout(() => {
      if (useScrollRef.current) {
      useScrollRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
      }
    }, 200);
  }

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      id: current?.id,
      answer: values.description,
    };

    function callback() {
      onClose();
      setLoading(false);
      getData();
    }

    function errorCallback(err) {
      setLoading(false);
      setMessage({ type: false, text: err });
    }

    REQUESTS.TICKETS.ANSWER(body, callback, errorCallback);
  };

  useEffect(() => {
    if (current) {
      form.setFields([
        {
          name: "description",
          value: current.description,
        },
      ]);
    } else {
      form.resetFields();
    }
  }, [current, visible]);

  return (
    <Drawer placement="right" onClose={onClose} open={visible}>
      <Form
        form={form}
        name="profile"
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        initialValues={{
          password: "",
        }}
      >
        {current ? (
          <h1 className={classes["message_title"]}>{current?.title}</h1>
        ) : (
          <h1 className={classes["message_title"]}>{support?.title}</h1>
        )}

        {current ? (
          <h2 className={classes["message_description"]}>{current?.message}</h2>
        ) : (
          <h2 className={classes["message_description"]}>{support?.message}</h2>
        )}

        <div className={classes["message_date_div"]}>
          {current ? (
            <p className={classes["message_date"]}>
              {getMyDate(current?.createdAt)}
            </p>
          ) : (
            <p className={classes["message_date"]}>
              {getMyDate(support?.createdAt)}
            </p>
          )}
        </div>

        <div className={classes["message_email_div"]}>
          <a
            target={"_blank"}
            href={`mailto:${current?.email || support?.email}`}
            className={classes["message_email"]}
          >
            {!current?.answer
              ? current?.email
              : `You have already answered this email. Follow the ${current?.email} to
            contact this customer again.`}
          </a>
        </div>

        {!current?.answer ? (
          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: "Please input description",
              },
            ]}
          >
            <TextArea rows={4} placeholder={"Enter your text"} />
          </Form.Item>
        ) : (
          <p className={classes["answer_text"]}>{current?.answer}</p>
        )}

        <ErrorMessage message={message} />
        <div
          className={
            classes[
              current?.answer ? "save_button_div_hidden" : "save_button_div"
            ]
          }
        >
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={classes["save_button"]}
              loading={loading}
            >
              {translation["Send"] || TEXT["Send"]}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};

export default SupportDrawer;
